
.Name {
    font-size: calc(16px + 1vmin);
    font-weight: bold;
    color: #F15823;
    line-height: 80%;
  }

.Subtitle {
    font-size: calc(10px + 1vmin);
}
